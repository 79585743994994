.feature {
  background-color: $moma-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $moma-black;
  margin-top: 80px;
  margin-bottom: 50px;
  text-align: center;

  .feature-title {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .feature-about-text {
    margin-bottom: 40px;
  }

  .feature-moma-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    width: auto;
    margin-bottom: 100px;

    @include mobile {
      height: 170px;
    }
  }

  .feature-container {
    width: 750px;

    @include mobile {
      width: 300px;
    }
  }

  .feature-number {
    margin-top: 40px;
    font-size: 25px;
    font-weight: 500;
  }

}
