
.navbar-blue {
  padding: 0 20px;
  font-family: 'Rubik', sans-serif;
  opacity: 1.0;

  @include mobile {
    font-size: 14px;
  }
}

.navbar-white {
  padding: 0 20px;
  font-family: 'Rubik', sans-serif;
  opacity: 1.0;
  background-color: $moma-white;

  @include mobile {
    font-size: 14px;
  }
}
