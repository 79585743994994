.title {
  background-color: $moma-light-green;
  // background-color: white;
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-img {
    opacity: 0.08;
    position:absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;

  }

  .title-container {
    width: 75%;
    position: absolute;
    z-index: 1;
  }

  h1 {
    color: $moma-white;
    // color: $moma-light-green;
    font-size: 70px;
    font-weight: bold;

    @include mobile {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 24px;
    color: $moma-white;
    // color: $moma-light-green;
    margin-top: 10px;
    font-weight: 600;
    @include mobile {
      font-size: 17px;
      margin-bottom: 30px;
    }
  }

  .title-first-char {
    color: $moma-black;
  }

  .landing-button {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 50px 0 0 50px;
    padding: 20px 0 5px 0;
    width: 60%;

    @include mobile {
      margin: 15px 0 0 25px;
      padding: 0;
    }

  }

  .button-icon {
    height: 60px;
    width: auto;

    @include mobile {
      height: 50px;
    }
  }

  .button-text{
    color: $moma-black;
    font-size: 18px;
    @include mobile {
      font-size: 17px;
    }
  }
}
