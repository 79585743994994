.timeline {
  width: 550px;
  height: 360px;
  border-bottom: solid;


  .timeline-subtitle {
    text-align: center;
  }

  .timeline-image {
    width: 460px;
    height: 270px;
    margin-left: 40px;
  }

  .timeline-circle-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    // position: absolute;
    // bottom: 3px;
  }

  .timeline-circle {
    width: 20px;
    height: 20px;
    background-color: $moma-black;
    border-radius: 50%;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
  }

  .timeline-circle-active {
    width: 20px;
    height: 20px;
    background-color: $moma-dark-green ;
    border: 4px solid $moma-black;
    border-radius: 50%;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    // position: absolute;
    // bottom: 0;
  }

  .timeline-circle-text {
    text-align: center;
    font-size: 12px;
  }
}
