$moma-white: #FFFFFF;
// $moma-black: #28454F;
// $moma-light-green: #41B5AF;
$moma-dark-green: #98B6BE;
$moma-light-grey: #DEE0E0;


$moma-light-green: #C0D2D7;
// $moma-light-green: #EEDCD0;
$moma-black: #474E5E;
