.filter {

  .filter-title {
    margin-bottom: 5px;
  }

  .filter-select {
    margin-bottom: 18px;
  }
}
