
.explore {
  width: 100%;
  height: 100%;
  display: flex;
  color: $moma-black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik', sans-serif;
  position: absolute;
  top: 100px;
  @include mobile {
    width: auto;
    margin: 15px 15px 0 20px;
    font-size: 14px;
  }

  .explore-title {
    border-left: 4px solid $moma-light-green;
    margin-bottom: 30px;
  }
  .explore-contianer {
    overflow: scroll;
  }

  .scroll-pre {
    height: 100px;
    width: 100%;
    background-color: inherit;
    position: fixed;
  }


  .explore-class-box {
    width: 70%;
    border-style: solid;
    border-width: thin;
    border-color: $moma-dark-green;
    // border-radius: 20px;
    display: flex;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background-color: $moma-light-grey;
      // text-shadow: 2px 2px 4px #DDBFAA;
    }
  }

  Button {
    margin-top: -1px;
  }

  .explore-icon {
    width: 20px;
    height: 20px;
  }

}
