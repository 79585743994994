.about {
  width: 100vw;
  height: 100vh;
  display: flex;
  color: $moma-black;
  flex-direction: column;
  justify-content: center;
  font-family: 'Rubik', sans-serif;

  @include mobile {
    width: auto;
    margin: 15px 15px 0 20px;
    font-size: 14px;
  }

  .about-title {
    border-left: 4px solid $moma-light-green;
    margin-bottom: 30px;
    @include mobile {
      margin-bottom: 10px;
    }
  }
  .about-moma-img {
    height: 300px;
    width: auto;
    padding-left: 80px;

    @include mobile {
      height: 170px;
      padding: 10px 0 0 0px;
    }

  }


}
